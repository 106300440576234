import { Card, Table } from "antd";
import { honoClient } from "./honoClient";
import { useQuery } from "@tanstack/react-query";

export default function MyTeam() {
    const teamQuery = useQuery({
        queryKey: ['team'],
        queryFn: async () => {
            const res = await honoClient.myTeam.$get({
                json: {},
            });

            return await res.json();
        },
    });


    return <Card title="团队管理">
        <Table
            dataSource={teamQuery.data}
            columns={[{
                title: 'teamId',
                dataIndex: 'teamId'
            },
            {
                title: 'userId',
                dataIndex: 'userId'
            },
            {
                title: 'primaryEmail',
                dataIndex: 'primaryEmail'
            },
            {
                title: 'primaryPhone',
                dataIndex: 'primaryPhone'
            },
            {
                title: 'role',
                dataIndex: 'role'
            }]}
        />
    </Card>
}